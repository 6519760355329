import { getStaticAsset } from '@/utils/staticAsset';
import { styled } from '@mui/system';
import React, { useState } from 'react';
import { PropsOf } from '@emotion/react';
import classNames from 'clsx';

const StyledImage = styled('img')<{
  loaded?: boolean;
}>`
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.loaded {
    opacity: 1;
  }

  &.noTransition {
    transition: none;
  }
`;

const successList = new Set<string>();
const failureList = new Set<string>();

const fallbackSrc = getStaticAsset('/assets/images/logo/m-logo.svg');

const ImgFallback = ({
  src,
  onLoad,
  onError,
  onErrored,
  className,
  ...props
}: {
  src: string[] | string;
  onErrored?: (e: React.SyntheticEvent) => void;
} & Omit<PropsOf<typeof StyledImage>, 'src'>) => {
  const [imgIndex, setImgIndex] = useState(0);

  const usedSrcs = (Array.isArray(src) ? src : [src]).filter(Boolean);

  const imgAlreadyLoaded = usedSrcs.find((src) => successList.has(src));

  return (
    <StyledImage
      src={
        failureList.has(usedSrcs.join('')) || !usedSrcs.length
          ? fallbackSrc
          : imgAlreadyLoaded ?? usedSrcs[imgIndex]
      }
      loading={imgAlreadyLoaded ? undefined : 'lazy'}
      className={classNames(className, {
        noTransition: imgAlreadyLoaded,
        loaded: imgAlreadyLoaded,
      })}
      onLoad={(e) => {
        onLoad?.(e);
        try {
          if (new URL((e.target as any).src).pathname === fallbackSrc) {
            onErrored?.(e);
          }
        } catch (e) {}
        if (imgAlreadyLoaded) return;
        successList.add(usedSrcs[imgIndex]);
        (e.target as HTMLImageElement).classList.add('loaded');
      }}
      key={imgIndex}
      onError={(e) => {
        onError?.(e);
        if (imgIndex < usedSrcs.length - 1) {
          setImgIndex(imgIndex + 1);
        } else {
          if ((e.target as HTMLImageElement).classList.contains('errored')) {
            return;
          }
          (e.target as HTMLImageElement).src = fallbackSrc;
          (e.target as HTMLImageElement).classList.add('errored');
          (e.target as HTMLImageElement).onerror = null;
          failureList.add(usedSrcs.join(''));
        }
      }}
      {...props}
    />
  );
};

export default ImgFallback;
