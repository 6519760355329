import { TGameListItem } from '@/apis/hub88';
import { GAME_PROVIDERS } from '@/constants/biz';
import { getStaticAsset } from '@/utils/staticAsset';

const getGameId = (game: TGameListItem) => {
  if (!game) {
    return '';
  }
  return game.gameId || game.gameSlug || game.gameName || '';
};

export const getGameCoverPath = (game: TGameListItem) => {
  let res = '';
  if (!game?.provider) {
    return res;
  }
  if (game.provider === 'SELF') {
    res = getStaticAsset(
      `/assets/images/casino/game-covers/moon-club/${getGameId(game)}.png`,
    );
  } else if (GAME_PROVIDERS[game.provider.toUpperCase()]) {
    res = getStaticAsset(
      `/assets/images/casino/game-covers/${game.provider.toLowerCase()}/${getGameId(
        game,
      )}.png`,
    );
  }

  // if (game?.provider === GAME_PROVIDERS.HUB88) {
  //   res = getStaticAsset(
  //     `/assets/images/casino/game-covers/hub88/${getGameSlug(game)}.png`,
  //   );
  // }

  // if (game?.provider === GAME_PROVIDERS.HACKSAW) {
  //   res = getStaticAsset(
  //     `/assets/images/casino/game-covers/hacksaw/${getGameSlug(game)}.png`,
  //   );
  // }

  return res;
};
