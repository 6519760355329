import GameCard from '@/components/features/game/GameCard';
import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useInViewport } from 'ahooks';

import { Wrapper } from '../../game/styles';

const PlaceHolderWrapper = styled(Box)`
  aspect-ratio: 1;
  box-shadow: 0 1px 5px #00000099;
  border-radius: 8px;
  height: 100%;
`;

const CardPlaceHolder = (props) => {
  const ref = useRef();

  const [inViewport] = useInViewport(ref);

  const [showed, setShowed] = useState(false);

  useEffect(() => {
    if (inViewport) {
      setShowed(true);
    }
  }, [inViewport]);

  return (
    <PlaceHolderWrapper ref={ref}>
      {!showed ? (
        <Wrapper
          href={''}
          onClick={(e) => {
            e.preventDefault();
          }}
        />
      ) : (
        <GameCard {...props} />
      )}
    </PlaceHolderWrapper>
  );
};

export default CardPlaceHolder;
