import { CircularProgress, SxProps, Theme, Typography } from '@mui/material';
import { appSidebarMobileSearchDrawerOpened } from '@/state/globalSettings';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect } from 'react';
import { TGameListItem } from '@/apis/hub88';
import { useTranslation } from 'next-i18next';
import useIsGameRestricted from '@/hooks/biz/casino/useIsGameRestricted';

import { isLoggedIn } from '@/state/userInfo';
import CasinoCoverImage from '@/components/biz/casino/CasinoCoverImage';
import { Wrapper, HoverBox } from './styles';

// export type TGameCardGame = {
//   name: string;
//   gameId?: string;
//   category: string;
//   thumbUrl?: string;
//   startUrl?: string;
// };

const GameCard = ({
  game,
  sx,
}: {
  game: TGameListItem;
  provider?: string;
  sx?: SxProps<Theme>;
}) => {
  const [t] = useTranslation();

  const isLogin = useAtomValue(isLoggedIn);

  const setSearchBar = useSetAtom(appSidebarMobileSearchDrawerOpened);
  const cardRef = React.useRef(null);
  const boundsRef = React.useRef(null);
  const glowRef = React.useRef(null);

  function rotateToMouse(e) {
    if (!cardRef.current || !boundsRef.current) return;
    const glowDiv = cardRef.current.querySelector('.glow');

    const mouseX = e.clientX;
    const mouseY = e.clientY;
    const leftX = mouseX - boundsRef.current.x;
    const topY = mouseY - boundsRef.current.y;
    const center = {
      x: leftX - boundsRef.current.width / 2,
      y: topY - boundsRef.current.height / 2,
    };
    // const distance = Math.sqrt(center.x ** 2 + center.y ** 2);

    //   cardRef.current.style.transform = `
    //   scale3d(1.07, 1.07, 1.07)
    //   rotate3d(
    //     ${center.y / 100},
    //     ${-center.x / 100},
    //     0,
    //     ${Math.log(distance) * 0.1}deg
    //   )
    // `;
    glowDiv.style.backgroundImage = `
    radial-gradient(
      circle at
      ${center.x * 2 + boundsRef.current.width / 2}px
      ${center.y * 2 + boundsRef.current.height / 2}px,
      #ffffff20,
      #0000000f
    )
  `;

    //   $card.querySelector('.glow').style.backgroundImage = `
    //   radial-gradient(
    //     circle at
    //     ${center.x * 2 + bounds.width / 2}px
    //     ${center.y * 2 + bounds.height / 2}px,
    //     #ffffff55,
    //     #0000000f
    //   )
    // `;
  }

  useEffect(() => {
    if (cardRef?.current) {
      cardRef.current.addEventListener('mouseenter', () => {
        boundsRef.current = cardRef.current.getBoundingClientRect();
        const exist = cardRef.current.querySelector('.glow');
        if (!exist) {
          const glowDiv = document.createElement('div');
          glowDiv.classList.add('glow');
          cardRef.current.appendChild(glowDiv);
        }
        document.addEventListener('mousemove', rotateToMouse);
      });

      cardRef.current.addEventListener('mouseleave', () => {
        const glowDiv = cardRef.current.querySelector('.glow');
        if (glowDiv) {
          glowDiv.remove();
        }
        document.removeEventListener('mousemove', rotateToMouse);
      });
    }
  }, []);

  const isUserCountryRestricted = useIsGameRestricted(game);

  const gameHref = () => {
    if (isUserCountryRestricted) return '#';
    const gameId =  game.gameId || game.slug;
    if (game.provider === 'SELF') return `/casino/${gameId}`;
    return `/casino/play/${gameId}`;
  };

  return (
    <Wrapper
      ref={cardRef}
      sx={sx}
      onClick={(e) => {
        if (isUserCountryRestricted) {
          e.preventDefault();
          return;
        }
        // onClick(e);
        setSearchBar(false);
      }}
      href={gameHref()}
    >
      {/* <div ref={glowRef} className="glow" /> */}
      <CasinoCoverImage game={game} />
      {isUserCountryRestricted ? (
        <HoverBox>
          {isUserCountryRestricted ? (
            <Typography variant="caption">
              {t('Sorry, this game is not available in your region')}
            </Typography>
          ) : null}
          {/*{isMobileMode && isFetchingGameURL ? <CircularProgress /> : null}*/}
        </HoverBox>
      ) : null}
    </Wrapper>
  );
};

export default GameCard;
