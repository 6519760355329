import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {
  Stack,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import useTheme from '@/theme/useTheme';
import { TGameListItem } from '@/apis/hub88';
import CardPlaceHolder from '@/components/features/common/GameCardCarousel/CardPlaceHolder';
import getReactNodeTextContent from '@/utils/dom/getReactNodeTextContent';
import Link from 'next/link';
import { Wrapper, ArrowWrapper, GameCardWrapper } from './styles';
import { useIsomorphicLayoutEffect } from 'ahooks';
import { isServer } from '@/configs';

export interface GameCardCarouselProps<T extends TGameListItem> {
  games: T[];
  viewAllLink?: string;
  title?: React.ReactNode;
  /**
   * @default autoCarousel
   * @param waterfall: waterfall
   * @param carousel: carousel
   * @param autoCarousel: at mobile screen, it will be carousel, at desktop screen, it will be waterfall
   */
  // mode?: 'autoCarousel' | 'waterfall' | 'carousel';
  showArrowControllers?: boolean;
  sxs?: {
    wrapperSX?: SxProps<Theme>;
  };
  horizontal?: boolean;
}

const scrollMap = {};

const GameCardCarousel = <T extends TGameListItem>({
  games = [],
  title,
  viewAllLink,
  showArrowControllers = true,
  // mode = 'autoCarousel',
  sxs = {
    wrapperSX: {},
  },
  horizontal = false,
}: GameCardCarouselProps<T>) => {
  const [t] = useTranslation();

  const ref = React.useRef<HTMLDivElement>(null);

  const { theme } = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const titleText = useMemo(() => {
    return getReactNodeTextContent(title);
  }, [title]);

  useIsomorphicLayoutEffect(() => {
    if (isServer) return;
    if (ref.current) {
      if (ref.current.clientWidth >= ref.current.scrollWidth) {
        setScrollState('none');
      } else {
        setScrollState('start');
      }
    }
    if (scrollMap[titleText]) {
      if (ref.current) {
        ref.current.scrollLeft = scrollMap[titleText];
      }
    }
  }, [games.length, titleText]);

  const [scrollState, setScrollState] = React.useState<
    'start' | 'middle' | 'end' | 'none'
  >('none');

  const wrapperSX: SxProps<Theme> = {
    justifyContent: games.length ? undefined : 'center',
    alignItems: games.length ? undefined : 'center',

    ...(horizontal
      ? {
          height: '160px',
          display: 'flex',
          [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(-2),
            marginRight: theme.spacing(-2),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            borderRadius: 0,
            display: 'grid',
            height: 'auto',
            ...(games.length >= 12
              ? {
                  gridTemplateRows: 'repeat(3, 27vw)',
                  gridAutoColumns: '27vw',
                  gridAutoFlow: 'column',
                }
              : {
                  gridTemplateRows: '27vw',
                  gridAutoColumns: '27vw',
                  gridAutoFlow: 'row',
                }),
          },
        }
      : {}),
    ...sxs.wrapperSX,
  };

  const hasGames = games.length > 0;

  if (!hasGames) {
    return null;
  }

  return (
    <Wrapper>
      <Stack direction={'row'} mb={2} gap={1} alignItems={'center'}>
        {title ? (
          typeof title === 'string' ? (
            <Typography variant={isMobile ? 'subtitle2' : 'h6'}>
              {title}
            </Typography>
          ) : (
            title
          )
        ) : null}
        {viewAllLink ? (
          <Typography
            variant="body1"
            color={'primary.main'}
            sx={{
              fontWeight: 10,
              letterSpacing: '0.04rem',

              textAlign: 'right',
              borderRadius: theme.spacing(1),
              border: '1px solid',
              borderColor: theme.palette.grey[600],
              marginLeft: 'auto',
              padding: theme.spacing(0.5, 1),
            }}
            component={'span'}
          >
            <Link href={viewAllLink} shallow={true} scroll={false}>
              {t('View All')}
            </Link>
          </Typography>
        ) : null}
        {(showArrowControllers && scrollState !== 'none' && (
          <ArrowWrapper
            sx={{
              flexGrow: viewAllLink ? 0 : 1,
              [theme.breakpoints.down('sm')]: {
                display: 'none',
              },
            }}
          >
            <Stack direction={'row'}>
              <KeyboardArrowLeft
                sx={{
                  cursor: scrollState === 'start' ? undefined : 'pointer',
                  opacity: scrollState === 'start' ? 0.2 : 1,
                  color: theme.palette.text.secondary,
                }}
                onClick={() => {
                  if (ref.current) {
                    ref.current.scrollBy({
                      left: -ref.current.clientWidth * 0.8,
                      behavior: 'smooth',
                    });
                  }
                }}
              ></KeyboardArrowLeft>
              <KeyboardArrowRight
                sx={{
                  cursor: scrollState === 'end' ? undefined : 'pointer',
                  opacity: scrollState === 'end' ? 0.2 : 1,
                  color: theme.palette.text.secondary,
                }}
                onClick={() => {
                  if (ref.current) {
                    ref.current.scrollBy({
                      left: ref.current.clientWidth * 0.8,
                      behavior: 'smooth',
                    });
                  }
                }}
              ></KeyboardArrowRight>
            </Stack>
          </ArrowWrapper>
        )) ||
          ''}
      </Stack>

      <GameCardWrapper
        ref={ref}
        onScroll={(e) => {
          const scrollLeft = (e.target as HTMLDivElement).scrollLeft;
          scrollMap[titleText] = scrollLeft;
          const target = e.target as HTMLDivElement;
          if (scrollLeft === 0) {
            setScrollState('start');
          } else if (
            scrollLeft + target.clientWidth >=
            target.scrollWidth - 1
          ) {
            setScrollState('end');
          } else {
            setScrollState('middle');
          }
        }}
        sx={wrapperSX}
      >
        {hasGames
          ? games.map((game, index) => {
              if (!game?.gameId) {
                console.log(games, game, 'no code!');
              }
              return <CardPlaceHolder game={game} key={game.gameId + index} />;
            })
          : null}
      </GameCardWrapper>
    </Wrapper>
  );
};

export default GameCardCarousel;
