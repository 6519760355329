import { ReactNode } from 'react';

// https://stackoverflow.com/questions/34204975/react-is-there-something-similar-to-node-textcontent
const getReactNodeTextContent = (node) => {
  if (['string', 'number'].includes(typeof node)) return node;
  if (node instanceof Array) return node.map(getReactNodeTextContent).join('');
  if (typeof node === 'object' && node)
    return getReactNodeTextContent(node.props.children);
};

export default getReactNodeTextContent;
