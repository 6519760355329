import { TGameListItem } from '@/apis/hub88';
import { useAtomValue } from 'jotai';
import { userLoginGeoInfoState } from '@/state/userInfo';

const useIsGameRestricted = (game: TGameListItem) => {
  return false;
  const userGeoLoginInfo = useAtomValue(userLoginGeoInfoState);

  const isUserCountryRestricted = (game.blockedCountries || []).includes(
    userGeoLoginInfo?.country,
  );

  return isUserCountryRestricted;
};

export default useIsGameRestricted;
