import { styled } from '@mui/material/styles';
import Link from 'next/link';
import { Box, Stack } from '@mui/material';

export const Wrapper = styled(Link)(({ theme }) => ({
  // background: theme.palette.grey[700],
  aspectRatio: '1',
  borderRadius: theme.spacing(1),
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  // padding: theme.spacing(2),
  flexDirection: 'column',
  position: 'relative',
  overflow: 'hidden',
  flexShrink: 0,
  boxShadow: '0 1px 5px #00000099',

  transitionDuration: '300ms',
  transitionProperty: 'transform, box-shadow',
  transitionTimingFunction: 'ease-out',
  transform: 'rotate3d(0)',
  '&:hover': {
    transitionDuration: '20ms',
    boxShadow: '0 5px 20px 5px #00000044',
  },
  ['> img']: {
    objectFit: 'cover',
    height: '100%',
  },
  ['.glow']: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: '0',
    top: '0',
    zIndex: '1',

    backgroundImage:
      'radial-gradient(circle at 50% -20%, #ffffff20, #0000000f)',
  },
}));

// export const BGBox = styled(Box)(({ theme }) => ({
//   position: 'absolute',
//   inset: 0,

// }));

export const HoverBox = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: 2,
  inset: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(0,0,0,0.8)',
  padding: theme.spacing(2),
  opacity: 0,
  transform: 'scale(1.1)',
  transition: 'all 0.3s ease-in-out',
  [':hover']: {
    opacity: 1,
    transform: 'scale(1)',
  },
}));
