import { TGameListItem } from '@/apis/hub88';
import { getGameCoverPath } from '@/components/features/game/helper';
import ImgFallback from '@/components/ui/LazyLoadedImage';
import { getStaticAsset } from '@/utils/staticAsset';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Image from 'next/image';
import { useState } from 'react';

const WrappedNextImg = styled(Image)`
  //object-fit: contain;
  height: 100%;
  width: 100%;
  max-width: 100%;
`;

const errorList = {};

const fallbackSrc = getStaticAsset('/assets/images/logo/m-logo.svg');

const CasinoCoverImageFallbackWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  placeContent: 'center',
  display: 'grid',
  padding: theme.spacing(1),
  position: 'relative',
  '& img': {
    left: 0,
    top: 0,
    position: 'absolute',
    opacity: 0.3,
    width: '100%',
    height: '100%',
  },
  '& h6': {
    wordBreak: 'break-word',
  },
}));

const CasinoCoverImage = ({
  game,
}: {
  game: TGameListItem;
  width?: number;
  height?: number;
}) => {
  const nextImgPath = getGameCoverPath(game);
  const [nextError, setNextError] = useState(!!errorList[nextImgPath] ?? false);

  const [fallbackError, setFallbackError] = useState(false);
  

  if (fallbackError || !game) {
    return (
      <CasinoCoverImageFallbackWrapper>
        <img src={fallbackSrc} alt="fallback"></img>
        <Typography variant={'h6'}>{game?.name ?? 'N/A'}</Typography>
      </CasinoCoverImageFallbackWrapper>
    );
  }

  return !nextError && nextImgPath ? (
    <WrappedNextImg
      src={nextImgPath} // 图片路径
      alt={game?.name || game?.gameId || 'alt'}
      width={150}
      height={150}
      onError={() => {
        setNextError(true);
        errorList[nextImgPath] = true;
      }}
    />
  ) : (
    <ImgFallback
      src={[game?.thumbUrl]}
      alt={game?.name || game?.gameId}
      loading={'eager'}
      onErrored={() => {
        setFallbackError(true);
      }}
    />
  );
};

export default CasinoCoverImage;
