import { styled } from '@mui/material/styles';
import { Stack, StackProps } from '@mui/material';
import React from 'react';

export const Wrapper = styled((props: StackProps) => <Stack {...props} />)(
  ({ theme }) => ({
    gap: 6,
    containerType: 'inline-size',
  }),
);

export const ArrowWrapper = styled((props: StackProps) => (
  <Stack {...props} direction={'row'} gap={2} />
))(({ theme }) => ({
  justifyContent: 'flex-end',

  alignSelf: 'center',
  ['> *']: {
    borderRadius: 12,
    padding: '4px',
  },
  ['svg']: {
    transition: 'opacity 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
}));

export const GameCardWrapper = styled(Stack)(({ theme }) => ({
  overflowX: 'scroll',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  gap: theme.spacing(2),
  perspective: '1500px',
  paddingBottom: theme.spacing(1),
  '::-webkit-scrollbar': {
    display: 'none',
  },
  display: 'grid',
  borderRadius: theme.spacing(1),
  ['--columns']: 6,
  gridTemplateColumns:
    'repeat(var(--columns), calc((100% - calc((var(--columns) - 1) * 16px) - 8px) / var(--columns)))',
  gridAutoFlow: 'row',
  [theme.breakpoints.down('md')]: {
    ['--columns']: 4,
  },
  [theme.breakpoints.down('sm')]: {
    /**
     * !!! IMPORTANT !!!
     * CSS hack here to handle the tg mobile browser
     */
    ['--columns']: 3,
  },
}));
